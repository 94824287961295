import Vue from 'vue';
import VueRouter from 'vue-router';
import ProductsPage from '@/views/ProductsPage.vue';
import ProductPageProjects from '@/views/ProductPageProjects.vue';
import ProductPageSD from '@/views/ProductPageSD.vue';
import ContactsPage from '@/views/ContactsPage.vue';
import AboutPage from '@/views/AboutPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/products',
  },
  {
    path: '/products',
    name: 'productsPage',
    component: ProductsPage,
  },
  {
    path: '/projects',
    name: 'productProjectsPage',
    component: ProductPageProjects,
  },
  {
    path: '/sd',
    name: 'productSDPage',
    component: ProductPageSD,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  }
  next();
});

export default router;
