<template>
  <section class="main-products">
    <div class="container">
      <div class="main-products-wrapper">
        <h2 class="products-title">
          <img src="@/assets/img/products/squares-title.svg" alt="Продукты">
          <span>{{getMessageByKey('main.kck.products')}}</span>
        </h2>
        <div class="main-products-grid">
          <div class="product-card product-card_type-1">
            <div class="product-card__inner">
              <div class="product-card__header">
                <img class="desktop-img"
                     src="@/assets/img/products/triangle.png" alt="KCK.Проекты">
                <img class="mobile-img" src="@/assets/img/products/triangle.svg" alt="KCK.Проекты">
                <div>
                  <h3 class="product-card__title product-card__title_single-line">
                    <strong>КСК.Проекты</strong>
                  </h3>
                  <p class="product-card__text-mobile">
                    Процессная система для управления продуктами и проектами
                  </p>
                  <router-link to="/projects" class="btn">Подробнее</router-link>
                </div>
              </div>
              <p class="product-card__text">
                Процессная система для управления продуктами и проектами на протяжении жизненного
                цикла — от идеи до вывода из эксплуатации
              </p>
            </div>
          </div>
          <div class="product-card product-card_type-2">
            <div class="product-card__inner product-card__inner_sd">
              <div class="product-card__header">
                <img class="desktop-img"
                     src="@/assets/img/products/triangle-turquoise.png" alt="KCK.SD">
                <img class="mobile-img"
                     src="@/assets/img/products/triangle-turquoise.svg" alt="KCK.SD">
                <div>
                  <h3 class="product-card__title">
                    <strong>КСК.Service Desk (KCK.SD)</strong>
                  </h3>
                  <p class="product-card__text-mobile">
                    Линейка решений для управления ИТ- и
                    бизнес-услугами на базе low-code платформы КСК.ИК
                  </p>
                  <router-link to="/sd" class="btn">Подробнее</router-link>
                </div>
              </div>
              <p class="product-card__text">
                Линейка решений класса ITSM для управления ИТ- и
                бизнес-услугами для качественной поддержки по принципу единого окна
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductsPage',
};
</script>
